<template>
  <div v-if="pagesCount > 1" class="custom-pagination">
    <div class="custom-pagination__btn-more">
      <show-more-button
        v-if="currentPage < pagesCount && isShowMore"
        :show-preloader="preloader"
        @click.prevent="onShowMore"
      >
        Показать ещё
      </show-more-button>
    </div>
    <pagination
      :last-page="pagesCount"
      :link-base="$route.path"
      :page-param="pageParam"
      :current-page="currentPage"
      :arrow-left-icon="arrowLeftIcon"
      :arrow-right-icon="arrowRightIcon"
      :arrow-left-height="arrowLeftHeight"
      :arrow-left-width="arrowLeftWidth"
      :arrow-right-height="arrowRightHeight"
      :arrow-right-width="arrowRightWidth"
      :additional-class="additionalClass"
      @pageSelected="pageSelectedHandler"
    />
  </div>
</template>

<script>
import ShowMoreButton from '~/components/elements/ShowMoreButton';
import Pagination from '~/components/elements/Pagination';

export default {
  name: 'CustomPagination',
  components: { Pagination, ShowMoreButton },
  props: {
    count: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    preloader: {
      type: Boolean,
      default: false,
    },
    pageParam: {
      type: String,
      default: 'page',
    },
    isShowMore: {
      type: Boolean,
      default: true,
    },
    arrowLeftIcon: {
      type: String,
      default: 'arrow-left-2',
    },
    arrowRightIcon: {
      type: String,
      default: 'arrow-left-2',
    },
    arrowLeftWidth: {
      type: [Number, String],
      default: 16,
    },
    arrowLeftHeight: {
      type: [Number, String],
      default: 18,
    },
    arrowRightWidth: {
      type: [Number, String],
      default: 16,
    },
    arrowRightHeight: {
      type: [Number, String],
      default: 18,
    },
    additionalClass: {
      type: String,
      default: '',
    },
  },
  computed: {
    pagesCount() {
      return Math.ceil(this.count / this.perPage)
    },
  },
  methods: {
    onShowMore() {
      this.$emit('showMore', { [this.pageParam]: this.currentPage + 1 })
    },
    pageSelectedHandler(selected) {
      this.$emit('selected', { [this.pageParam]: selected })
    },
  },
}
</script>

