<template>
  <div class="pagination" :class="additionalClass">
    <a
      v-if="currentPage !== 1"
      :href="getLinkHref(previousPage)"
      class="pagination__button pagination__button--prev"
      @click.prevent="handlePageSelected(previousPage)"
    >
      <svg-icon :name="arrowLeftIcon" :width="arrowLeftWidth" :height="arrowLeftHeight" />
    </a>
    <span v-else class="pagination__button pagination__button--prev pagination__button--disabled">
      <svg-icon :name="arrowLeftIcon" :width="arrowLeftWidth" :height="arrowLeftHeight" />
    </span>

    <template v-for="page in pages">
      <span v-if="page === currentPage" :key="page" class="pagination__button pagination__button--active">
        {{ page }}
      </span>
      <a
        v-else
        :key="page"
        :href="getLinkHref(page)"
        class="pagination__button"
        @click.prevent="handlePageSelected(page)"
      >
        {{ page }}
      </a>
    </template>

    <a
      v-if="currentPage !== lastPage"
      :href="getLinkHref(nextPage)"
      class="pagination__button pagination__button--next"
      @click.prevent="handlePageSelected(nextPage)"
    >
      <svg-icon :name="arrowRightIcon" :width="arrowRightWidth" :height="arrowRightHeight" />
    </a>
    <span v-else class="pagination__button pagination__button--next pagination__button--disabled">
      <svg-icon :name="arrowRightIcon" :width="arrowRightWidth" :height="arrowRightHeight" />
    </span>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    lastPage: {
      type: Number,
      required: true,
    },
    linkBase: {
      type: String,
      default: '',
    },
    pageParam: {
      type: String,
      default: 'page',
    },
    currentPage: {
      type: Number,
      required: true,
    },
    arrowLeftIcon: {
      type: String,
      default: 'arrow-left-2',
    },
    arrowRightIcon: {
      type: String,
      default: 'arrow-left-2',
    },
    arrowLeftWidth: {
      type: [Number, String],
      default: 16,
    },
    arrowLeftHeight: {
      type: [Number, String],
      default: 18,
    },
    arrowRightWidth: {
      type: [Number, String],
      default: 16,
    },
    arrowRightHeight: {
      type: [Number, String],
      default: 18,
    },
    additionalClass: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      disabled: true,
    };
  },
  computed: {
    previousPage() {
      return this.currentPage > 1 ? this.currentPage - 1 : 1;
    },
    nextPage() {
      return this.currentPage < this.lastPage ? this.currentPage + 1 : this.lastPage;
    },
    pages() {
      let start = this.currentPage - 2;
      if (start > this.lastPage - 4) {
        start = this.lastPage - 4;
      }
      if (start < 1) {
        start = 1;
      }
      const count = start + 5 > this.lastPage ? this.lastPage - start + 1 : 5;
      return Array.apply(0, Array(count)).map((_e, i) => {
        return i + start;
      });
    },
  },
  methods: {
    getLinkHref(page) {
      if (page <= 1 || page > this.lastPage) {
        return this.linkBase
      }

      return this.linkBase ? `${this.linkBase}?${this.pageParam}=${page}` : ''
    },
    handlePageSelected(selected) {
      this.$emit('pageSelected', selected)
    },
  },
};
</script>
